import { MouseEventHandler } from 'react'
import { Button, Icon, chakra } from '@chakra-ui/react'
import GoogleIcon from 'public/img/google_icon.svg'

export function GoogleLogin({
    onClick,
    emailLoading,
}: {
    onClick: MouseEventHandler
    emailLoading: boolean
}): JSX.Element {
    return (
        <Button
            onClick={onClick}
            iconSpacing="3"
            data-testid="google-login-button"
            variant="white"
            isDisabled={emailLoading}
            display="flex"
            gap="6px"
        >
            <Icon as={GoogleIcon} boxSize="20px" />

            <chakra.span display={{ base: 'none', xs: 'inline' }}>
                Continue with Google
            </chakra.span>
        </Button>
    )
}
